<template>
  <div class="px-5 lg:px-0">
    <div
      class="w-full lg:w-1/2 mx-auto bg-white my-10 py-5 px-0 lg:px-2 rounded shadow"
    >
      <div
        class="w-full mx-auto px-2 py-4 rounded-cu text-center text-lg text-blackdok font-bold"
        v-if="success"
      >
        Votre inscription à notre événement à bien été enregistrée. <br />Nous
        sommes ravis de vous compter parmi nous.
      </div>
      <div class="w-6/12 mx-auto" v-if="!success">
        <h4
          class="font-bold text-lg text-blackdok whitespace-no-wrap my-8 text-center"
        >
          Veuillez remplir les informations ci-dessous
        </h4>
        <div class="mb-3">
          <div class="mb-3 lg:mb-0">
            <label for="nom">Numéro INPE</label>
            <div class="relative py-0 mt-2">
              <input
                v-model="form.inpe"
                type="text"
                placeholder="Numéro INPE"
                :class="error.inpe ? 'border-red-500' : 'border-gray-300'"
                class="w-full bg-white border border-solid py-3 px-3 rounded-lg text-dark"
              />
              <button
                @click="searchByInpe()"
                :class="
                  error.inpe
                    ? 'border-red-500 border'
                    : 'border-0 border-gray-300'
                "
                class="border-l-0 rounded-r absolute right-0 top-0 h-full cursor-pointer px-3"
                type="button"
              >
                Chercher
              </button>
            </div>
          </div>
          <p v-if="error.inpe">{{ error.inpe }}</p>
        </div>
        <div class="grid md:grid-cols-2 gap-4">
          <div class="mb-3 lg:mb-0 lg:mr-1">
            <label for="prenom">Prénom</label>
            <input
              v-model="form.firstName"
              type="text"
              placeholder="Prénom"
              class="w-full bg-white border border-solid border-gray-300 py-3 px-3 rounded-lg text-dark mt-2"
            />
          </div>
          <div class="mb-3 lg:mb-0">
            <label for="nom">Nom</label>
            <input
              v-model="form.lastName"
              type="text"
              placeholder="Nom"
              class="w-full bg-white border border-solid border-gray-300 py-3 px-3 rounded-lg text-dark mt-2"
            />
          </div>
        </div>
        <div class="mb-3 lg:mb-0 lg:my-3">
          <label for="email">Adresse mail</label>
          <input
            v-model="form.email"
            type="email"
            placeholder="exemple@email.com"
            :class="error.email ? 'border-red-500' : 'border-gray-300'"
            class="w-full bg-white border border-solid py-3 px-3 rounded-lg text-dark mt-2"
          />
          <p v-if="error.email">{{ error.email }}</p>
        </div>
        <div class="mb-3 lg:mb-0 lg:my-3">
          <label for="phone">Mobile</label>
          <input
            v-model="form.mobile"
            type="text"
            placeholder="0600000000"
            :class="error.mobile ? 'border-red-500' : 'border-gray-300'"
            class="w-full bg-white border border-solid py-3 px-3 rounded-lg text-dark mt-2"
          />
          <p v-if="error.mobile">{{ error.mobile }}</p>
        </div>
        <div class="mb-3 lg:mb-0 lg:my-3">
          <label for="phone">Téléphone</label>
          <input
            v-model="form.fixe"
            type="text"
            placeholder="0500000000"
            :class="error.fixe ? 'border-red-500' : 'border-gray-300'"
            class="w-full bg-white border border-solid py-3 px-3 rounded-lg text-dark mt-2"
          />
          <p v-if="error.fixe">{{ error.fixe }}</p>
        </div>
        <div class="mb-3 lg:mb-0 lg:my-3">
          <label for="email">Adresse</label>
          <input
            v-model="form.address"
            type="address"
            placeholder="Adresse"
            class="w-full bg-white border border-solid border-gray-300 py-3 px-3 rounded-lg text-dark mt-2"
          />
        </div>
        <div class="mb-3 lg:mb-0 lg:my-3">
          <label for="city">Ville</label>
          <input
            v-model="form.city"
            type="address"
            placeholder="Ville"
            class="w-full bg-white border border-solid border-gray-300 py-3 px-3 rounded-lg text-dark mt-2"
          />
        </div>
        <div class="mb-3 lg:mb-0 lg:my-3">
          <label for="specialite">Quelle est votre spécialité ?</label>
          <div
            :class="error.speciality ? 'border-red-500' : 'border-gray-300'"
            class="relative mt-2"
          >
            <v-select
              id="specialite"
              :filterable="false"
              @search="onSearch"
              @input="onSelectSpec"
              :reduce="spec => spec.slug"
              :options="specialities"
              label="name"
              :class="error.speciality ? 'border-red-500' : 'border-gray-300'"
              class="medicament-vs"
            >
              <template slot="option" slot-scope="option">
                {{ option.name }}
              </template>
              <template slot="no-options"> ajoutez une spécialité.! </template>
            </v-select>
            <p v-if="error.speciality">{{ error.speciality }}</p>
          </div>
        </div>
        <button
          @click="onClick"
          class="bg-dokBlue-ultra text-white border border-solid border-dokBlue-ultra py-3 px-2 rounded-lg text-base w-full cursor-pointer my-8 font-semibold"
        >
          Valider mon inscription
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VSelect from "vue-select";
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export default {
  data() {
    return {
      form: {
        inpe: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        mobile: "",
        address: "",
        speciality: "",
        by_invitation: true,
        type: "lead"
      },
      specialities: [],
      success: false,
      error: {
        inpe: null,
        mobile: null,
        fixe: null,
        email: null,
        speciality: null
      }
    };
  },
  components: {
    VSelect
  },
  mounted() {
    var el = this;
    this.$store
      .dispatch("doctor/GET_SPECIALITIES_LIST", {
        params: {
          term: "",
          limit: 10
        }
      })
      .then(function(response) {
        el.specialities = response.data;
      });
  },
  methods: {
    onSelectSpec(value) {
      this.form.speciality = value;
    },
    onSearch(search, loading) {
      loading(true);
      var el = this;
      this.$store
        .dispatch("doctor/GET_SPECIALITIES_LIST", {
          params: {
            term: search,
            limit: 10
          }
        })
        .then(function(response) {
          // Update options
          loading(false);
          el.specialities = response.data;
        });
    },
    searchByInpe: function() {
      var el = this;
      el.$store.dispatch("doctor/PREFILL_BY_INPE", {
        params: { inpe: el.form.inpe },
        onData: el.prefillInput
      });
    },
    prefillInput: function(data) {
      // //console.log(this.data)
      if (data.ok) {
        var praticien = data.data;
        let firstSpace = praticien.nom.indexOf(" ");
        this.form.firstName = praticien.nom.substr(0, firstSpace);
        this.form.lastName = praticien.nom.substr(firstSpace + 1);
        this.form.address = praticien.address;
        this.form.city = praticien.city;
        if (praticien.phone.startsWith("05")) {
          this.form.fixe = praticien.phone;
          this.form.mobile = "";
        } else {
          this.form.mobile = praticien.phone;
          this.form.fixe = "";
        }
        this.form.speciality = praticien.speciality;
        // alert('is not mobile'+praticien.phone.startsWith('05'))
      }
    },

    async validateForm() {
      this.error = {
        inpe: null,
        mobile: null,
        fixe: null,
        email: null,
        speciality: null
      };
      var has_error = false;
      for (var key in this.error) {
        if (!this.form[key]) {
          has_error = true;
          this.error[key] = "Ce champ est obligatoire";
        }
      }
    },
    async validatePhone(phone, error) {
      if (phone) {
        try {
          var number = phoneUtil.parse(phone, "MA");
          //console.log(number);
          if (!phoneUtil.isValidNumberForRegion(number, "MA")) {
            return false;
            error = "le numéro n'est pas valide ";
            // phone = phoneUtil.format(number, PNF.E164);
          }
        } catch (error) {
          return true;
          // has_error = true
          error = "le numéro n'est pas valide";
        }
      }

      return error;
    },
    onClick: function() {
      var has_error = false;
      has_error = this.validateForm();
      if (!has_error) {
        has_error = this.validatePhone(this.form.mobile, this.error.mobile);
        has_error = this.validatePhone(this.form.fixe, this.error.fixe);
      }

      if (!has_error) {
        this.$store.dispatch("doctor/GATHER_INFO", {
          data: this.form,
          onData: ({ ok, message }) => {
            // this.$vs.loading.close();
            this.$vs.notify({
              time: 4000,
              // title: ok ? "Success" : "Erreur",
              text: message,
              color: ok ? "success" : "danger",
              iconPack: "feather",
              icon: "icon-check"
            });
            if (ok) {
              this.form = {
                inpe: "",
                firstName: "",
                lastName: "",
                email: "",
                speciality: "",
                phone: "",
                mobile: "",
                address: ""
              };
              this.success = true;
            }
          }
        });
      }
      //   this.$vs.loading.close();
    }
  }
};
</script>

<style>
button {
}

label {
  font-size: 13px;
  font-weight: bold;
  @apply text-gray-600;
}

.vs__selected-options {
  flex-wrap: nowrap;
}

.vs--single .vs__selected {
  font-size: 11px;
  white-space: nowrap;
}

.medicament-vs .spic__search .vs__search::placeholder {
  @apply text-gray-600;
}

.medicament-vs,
.medicament-vs .vs__dropdown-toggle,
.medicament-vs .vs__dropdown-menu {
  border-radius: 0;
  @apply rounded-cu;
  @apply border-gray-300;
  @apply border;
}

.medicament-vs .vs__dropdown-toggle {
  height: 38px;
  width: 100%;
}

.medicament-vs .vs__clear,
.medicament-vs .vs__open-indicator {
  fill: black;
}
</style>
